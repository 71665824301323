import styled from "styled-components";

export const KeyContainer = styled.div`
    font-size: 1rem;
    margin: 1rem;
    margin-left: 0.2rem;

    div {
        margin-top: 0.25rem;
    }
`;
