import {HeroContainer} from "./Logo.styled";


const asciiArtLetters = {
    'A': `
 .d8b. 
d8' \`8b
88ooo88
88~~~88
88   88
YP   YP
    `,
    'F': `
d88888b
88'    
88ooo  
88~~~  
88     
YP     
    `,
    'R': `
d8888b.
88  \`8D
88oobY'
88\`8b  
88 \`88.
88   YD
    `,
    'N': `
d8b   db
888o  88
88V8o 88
88 V8o88
88  V888
VP   V8P
    `,
    'K': `
db   dD
88 ,8P'
88,8P  
88\`8b  
88 \`88.
YP   YD
    `,
    ' ': `
  
  
  
  
  
  
  
`,
    'G': `
 d888b 
88' Y8b
88     
88  ooo
88. ~8~
 Y888P 
    `
}

function buildWord(word) {
    let lines = []; // Stores individual lines of the final output

    // Find the maximum number of lines in a single letter
    let maxLines = 0;
    for (const letter of word) {
        const letterLines = asciiArtLetters[letter].split('\n').length;
        maxLines = Math.max(maxLines, letterLines);
    }

    // Build each line of the output
    for (let i = 0; i < maxLines; i++) {
        let wordLine = '';
        for (const letter of word) {
            const letterLines = asciiArtLetters[letter].split('\n');
            wordLine += letterLines[i] || ' '; // Add space if a line is missing
        }
        lines.push(wordLine);
    }

    return lines.join('\n'); // Combine lines into a single string
}

const frankAsciiArt = buildWord('F R A N K   N G');

export default function Logo() {
    return (
        <HeroContainer>
            <pre> {frankAsciiArt} </pre>
        </HeroContainer>
    )
}