import Logo from "./Logo";
import {commands} from "./Terminal";
import {KeyContainer} from "./Welcome.styled";

export default function Welcome() {
    return (
        <div>
            <Logo/>
            <h1>List of commands:</h1>
            <KeyContainer>
                {commands.map((cmd, index) => {
                    return (<div key={index}>
                        {cmd.cmd} {"\xA0\xA0\xA0"} {cmd.desc}
                    </div>)
                })}
                <br/>
                <div>Tab / Ctrl+I {"\xA0"} autocompletes the command</div>
                <div>Up / Arrow {"\xA0\xA0\xA0"} go back to previous command</div>
                <div>Ctrl+L {"\xA0\xA0\xA0"} clear the terminal</div>
                <div>Ctrl+C {"\xA0\xA0\xA0"} cancel the current command</div>
                <br/>
            </KeyContainer>
        </div>
    )
}