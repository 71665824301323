import styled from "styled-components";

export const MobileBr = styled.br`
  @media (min-width: 550px) {
    display: none;
  }
`;

export const MobileSpan = styled.span`
  line-height: 1.5rem;
  margin-right: 0.75rem;
`;

export const User = styled.span`
  color: var(--secondary-color);
`;

export const WebsiteName = styled.span`
  color: var(--primary-color);
`;

export const Wrapper = styled.span`
  display: inline-block;
  margin-right: 0.75rem;
`;