import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 0.75rem 1.25rem 1.25rem;
    display: flex;
    flex-direction: column-reverse;
    max-height: calc(100vh - 2rem);
    overflow-y: auto;
`;

export const Hints = styled.span`
    margin-right: 0.875rem;
`;

export const Form = styled.form`
    @media (min-width: 550px) {
        display: flex;
    }
`;

export const Input = styled.input`
    flex-grow: 1;

    @media (max-width: 550px) {
        min-width: 85%;
    }
`;

export const CmdNotFound = styled.div`
    margin-top: 0.25rem;
`;
