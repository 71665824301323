import WhoAmI from "./WhoAmI";
import Welcome from "./Welcome";

export default function Output({index, cmd}) {
    return (<div>
            {{
                whoami: <WhoAmI/>,
                welcome: <Welcome/>,
            }[cmd]}
        </div>)
}