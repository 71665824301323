import {MobileBr, MobileSpan, User, WebsiteName, Wrapper} from "./TerminalPrompt.styled";

function TerminalInfo() {
    return (
        <Wrapper>
            <User>guest</User>@<WebsiteName>techy.asia</WebsiteName>:~$
        </Wrapper>
    );
};

export default function TerminalPrompt(props) {
    const {cmd} = props;
    return (
        <div>
            <TerminalInfo/>
            <MobileBr/>
            <MobileSpan>&#62;</MobileSpan>
            {cmd && <span>{cmd}</span>}
        </div>
    )
}