import {WhoAmIWrapper} from "./WhoAmI.styled";

export default function WhoAmI() {
    return (<WhoAmIWrapper><pre>
{`
CONTACT
-------
Name:      Frank Ng
Email:     frankng.sg@email.com
Portfolio: https://github.com/frankng-sg  

SKILLS
------
* Core Languages: Go (Gin, gRPC), JavaScript (Node.js / Express.js)
* API Development: RESTful API Design
* Databases: MySQL, MongoDB, Redis
* Message Queues: RabbitMQ, Kafka
* Cloud: AWS (EC2, S3, RDS), Kubernetes, Docker 

EXPERIENCE
----------
* GXS Bank        | Senior Software Engineer | Dec 2023 - Present
* NTUC Enterprise | Senior Software Engineer | Oct 2021 - Nov 2023
* HOPE Technik    | Software Engineer        | Jun 2020 - Jun 2021
* Thermo Fisher   | Software Engineer        | Jun 2019 - Jun 2020

PROJECTS
--------
* 

EDUCATION
---------
* B.Sc. Electrical Electronics Engineering, Nanyang Technological University 
`}
        </pre></WhoAmIWrapper>)
}