import {Wrapper, Hints, Form, Input, CmdNotFound} from "./Terminal.styled";
import _ from "lodash";
import TerminalPrompt from "./TerminalPrompt";
import Output from "./Output";
import React, {
    createContext, useCallback, useEffect, useRef, useState,
} from "react";


export const termContext = createContext({
    arg: [], history: [], refresh: false, index: 0,
});

export const commands = [
    {cmd: "welcome", desc: "introduce shell and commands"},
    {cmd: "whoami", desc: "display my resume"},
];

export default function Terminal() {
    const containerRef = useRef(null);
    const inputRef = useRef(null);

    const [inputCmd, setInputCmd] = useState('');
    const [cmdHistory, setCmdHistory] = useState([{cmd:'welcome', skipped: false}]);
    const [refresh, setRefresh] = useState(false);
    const [hints, setHints] = useState([])
    const [cmdHistoryIndex, setCmdHistoryIndex] = useState(-1);

    const handleInputCmdChange = useCallback(e => {
        setRefresh(false);
        setInputCmd(e.target.value);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setCmdHistory([{cmd:inputCmd, skipped: false}, ...cmdHistory]);
        setInputCmd('');
        setCmdHistoryIndex(-1);
        setHints([])
        setRefresh(true);
    }

    const clearHistory = () => {
        setCmdHistory([]);
        setHints([]);
    }

    const handleDivClick = () => {
        inputRef?.current?.focus();
    }

    useEffect(() => {
        document.addEventListener(("click"), handleDivClick);
        return () => {
            document.removeEventListener("click", handleDivClick);
        }
    }, [containerRef]);

    const handleKeyDown = (e) => {
        setRefresh(false);
        const ctrlI = e.ctrlKey && e.key.toLowerCase() === 'i';
        const ctrlL = e.ctrlKey && e.key.toLowerCase() === 'l';
        const ctrlC = e.ctrlKey && e.key.toLowerCase() === 'c';

        if (ctrlL) {
            clearHistory();
        } else if (e.key === "ArrowUp") {
            if (cmdHistoryIndex + 1 >= cmdHistory.length) return;
            setInputCmd(cmdHistory[cmdHistoryIndex + 1].cmd);
            setCmdHistoryIndex(index => index + 1);
            inputRef?.current?.blur();
        } else if (e.key === "ArrowDown") {
            if (cmdHistoryIndex <= 0) {
                setInputCmd('');
                setCmdHistoryIndex(-1);
                return;
            }
            setInputCmd(cmdHistory[cmdHistoryIndex - 1].cmd);
            setCmdHistoryIndex(index => index - 1);
            inputRef?.current?.blur();
        } else if (ctrlC) {
            setCmdHistory([{cmd: inputCmd, skipped: true}, ...cmdHistory]);
            setInputCmd('');
            setCmdHistoryIndex(-1);
            setHints([])
            setRefresh(true);
        } else if (ctrlI || e.key === "Tab") {
            e.preventDefault();
            if (!inputCmd) return;
            let suggestions = [];
            commands.forEach(({cmd}) => {
                if (_.startsWith(cmd, inputCmd)) {
                    suggestions.push(cmd);
                }
            });
            if (suggestions.length === 1) {
                setInputCmd(suggestions[0]);
                setHints([])
            } else {
                setHints(suggestions);
            }
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            inputRef?.current?.focus();
        }, 1);
        return () => clearTimeout(timer);
    }, [inputRef, inputCmd, cmdHistoryIndex]);

    return (<Wrapper ref={containerRef}>
        {hints.length > 1 && <div>
            {hints.map(hCmd => (<Hints key={hCmd}>{hCmd}</Hints>))}
        </div>}
        <Form onSubmit={handleSubmit}>
            <label htmlFor="terminal-input">
                <TerminalPrompt/>
            </label>
            <Input
                title="terminal-input"
                type="text"
                id="terminal-input"
                autoComplete="off"
                spellCheck="false"
                autoFocus
                autoCapitalize="off"
                ref={inputRef}
                value={inputCmd}
                onKeyDown={handleKeyDown}
                onChange={handleInputCmdChange}
            />
        </Form>
        {cmdHistory.map(({cmd,skipped}, index) => {
            const cmdArray = _.split(_.trim(cmd), ' ');
            const validCommand = _.find(commands, {cmd: cmdArray[0]});
            const ctx = {
                arg: _.drop(cmdArray), history: cmdHistory, refresh, index, clearHistory,
            }
            return (
                <div key={_.uniqueId(`${cmd}_`)}>
                    <TerminalPrompt cmd={cmd}/>
                    {
                        validCommand ? (
                            <termContext.Provider value={ctx}>
                                <Output index={index} cmd={cmdArray[0]}/>
                            </termContext.Provider>
                        ) : !skipped ? (<CmdNotFound>command not found: {cmd}</CmdNotFound>) : null
                    }
                </div>
            )
        })}
    </Wrapper>)
}